import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$('#slick-car').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 6000,
  appendArrows: '#slick-car-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left-orange"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right-orange"></span></button>',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: false,
      },
    },
  ],
});

$('#slick-review').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 6000,
  appendArrows: '#slick-review-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left-green"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right-green"></span></button>',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: false,
      },
    },
  ],
});

// setTimeout(() => {
//   $('#slick-home .slick-active').addClass('animation');
// }, 250);

// $('#slick').on('afterChange', () => {
//   $('#slick-home .slick-active').addClass('animation');
// });

// $('#slick').on('beforeChange', () => {
//   $('#slick-home .slick-active').removeClass('animation');
// });
