import { Modal } from 'bootstrap';

$('#modal-loader').hide();

const handleModal = (e) => {
  const { currentTarget } = e;
  $('#modal .modal-dialog').removeClass('modal-lg modal-sm modal-dialog-centered');
  const href = $(currentTarget).attr('href');
  const size = $(currentTarget).data('bs-size');
  const align = $(currentTarget).data('bs-align');
  $('#modal .modal-dialog').addClass(size).addClass(align);
  return href;
};

function loadModal(href) {
  $('#modal .modal-body').html();
  $('#modal-loader').show();

  $('#modal .modal-content').load(`${href} #modal-content`, (_, status, xhr) => {
    if (status === 'success') {
      const myModal = new Modal(document.getElementById('modal'), {});
      myModal.show();
      $('.trigger-modal-inner').on('click', (e) => {
        myModal.hide();
        loadModal(handleModal(e));
        return false;
      });
    } else {
      const msg = 'Sorry but there was an error: ';
      throw new Error(`${msg + xhr.status} ${xhr.statusText}`);
    }
    $('#modal-loader').hide();
  });
}

$('.trigger-modal').on('click', (e) => {
  loadModal(handleModal(e));
  return false;
});
