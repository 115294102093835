import { Modal } from 'bootstrap';
import aos from "aos";

let can_request = true;

function f_resa_options() {
  $('#options input[type=checkbox]').on('change', function () {
    checkbox($(this));
    request();
  });

  $('#options .quantite select').on('change', function () {
    $(this).parents('.item').find('input[type=checkbox]').prop('checked', $(this).val() !== '0');
    request();
  });

  $('#options .quantite').on('click', function () {
    if ($(this).hasClass('disabled')) {
      $(this).parent().find('label').trigger('click');
      request();
    }
  });

  // Initialisation
  //request();
  $('#options input[type=checkbox]').each(function () {
    checkbox($(this));
  });

  function checkbox(self) {
    var $quantite = self.parent().parent().find('.quantite');

    if (self.is(":checked")) {
      $quantite.removeClass('disabled');
      $quantite.find('select').val(1).change();
    } else {
      $quantite.addClass('disabled');
      $quantite.find('select').val(0).change();
    }
  }

  function request() {
    if(can_request){
      can_request = false;
      $.ajax({
        method: "POST",
        url: $("#options").data('ajax-url'),
        data: $("#devis").serialize(),
        success: function(data) {
          $("._aside").show();
          $("._aside").html(data.aside);
          $("#_total").html(data.total);
        }
      });

      setTimeout(function(){ can_request = true; }, 700);
    }
  }

}

function f_Carrosserie(){
  $("#carrosserie .dropdown-item").on('click', function() {
    console.log($(this).attr('value'))
    if ($(this).attr('value') !== '') {
      const selectedCarrosserie = $(this).attr('value');
      $('.resa-car').hide();
      $('.resa-car').each(function() {
        const carrosseries = $(this).attr('data-carrosserie').split(',');
        if( carrosseries.includes(selectedCarrosserie) ) {
          $(this).show();
        }
      });
    } else {
      $('.resa-car').show();
    }

    if ($(this).attr('value')) {
      $('#carrosserie-nom').text($(this).text());
    } else {
      $('#carrosserie-nom').text('');
    }
    aos.refresh();
    //$('#search-count').text($('.resa-step-1:visible:not(.unavailable)').length);
  });
  $("#carrosserie").trigger('change');
}
f_Carrosserie();

$( document ).ready(function() {
  if ($('#options').length > 0) {
    f_resa_options();
  }
  if ($('#carrosserie').length > 0) {
    f_Carrosserie();
  }
});

